import canUseDom from '@argos/utils/esnext/canUseDom'
import { getCookieValue, setCookie } from './cookiesHelper'

// Read and honour user's consent preferences
const hasConsentedToFunctionalCookies = () => {
  return canUseDom() &&
    global.window.dataUtils &&
    typeof global.window.dataUtils.consentedToFunctionalCookies === 'function'
    ? global.window.dataUtils.consentedToFunctionalCookies()
    : false
}

// Get the AB test variant for the user
const getABTestVariant = (testName, variant, control, variantPercentage, controlPercentage, fallback = '3') => {
  const storedVariant = canUseDom() && getCookieValue(testName)
  if (storedVariant) {
    return storedVariant
  }

  const randomValue = Math.random()
  let assignedVariant

  // if random value is less than variant percentage, assign variant cookie value
  if (randomValue < variantPercentage) {
    assignedVariant = variant
    // if random value is less than variant percentage + control percentage, assign control cookie value
  } else if (randomValue < variantPercentage + controlPercentage) {
    assignedVariant = control
    // if random value is greater than variant percentage + control percentage, assign default fallback cookie value
  } else {
    assignedVariant = fallback
  }

  // Store the assigned variant in a cookie (with 30 days expiry)
  setCookie(testName, assignedVariant, 30, { secure: true })

  return assignedVariant
}

// Check if the page route is excluded from the AB test
const isRouteExcluded = (excludedRoutes) => {
  const pathname = (canUseDom() && global.window.location.pathname) || ''
  return excludedRoutes.some((route) => pathname.indexOf(route) === 0)
}

export { getABTestVariant, hasConsentedToFunctionalCookies, isRouteExcluded }
