/**
 * Get value of specific cookie
 * @returns {string} The value of the cookie
 */
function getCookieValue(name) {
  const cookies = global.document.cookie
  const cookieByName = cookies.split(';').filter((cookie) => cookie.trim().startsWith(`${name}=`))
  return cookieByName && cookieByName.length ? cookieByName[0].split('=')[1] : ''
}

/**
 * Check if a specific cookie exists by its name
 * @param {string} name - The name of the cookie
 * @returns {boolean} True if the cookie exists, false otherwise
 */
function doesCookieExist(name) {
  return getCookieValue(name) !== ''
}

/**
 * Set a new cookie
 * @param {string} name - The name of the cookie
 * @param {string} value - The value of the cookie
 * @param {number} [days] - The number of days until the cookie expires
 */
function setCookie(name, value, days, options = {}) {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = `; expires=${date.toUTCString()}`
  }
  let cookieString = `${name}=${value || ''}${expires}; path=/`

  if (options.sameSite) {
    cookieString += `; SameSite=${options.sameSite}`
  }
  if (options.secure) {
    cookieString += '; Secure'
  }
  if (options.httpOnly) {
    cookieString += '; HttpOnly'
  }

  global.document.cookie = cookieString
}

export { doesCookieExist, getCookieValue, setCookie }
