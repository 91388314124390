/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import Image from '@boltui/image'
import getSiteSection from '../../helpers/getSiteSection'
import styles from './Logo.scss'
import { isNotABot } from '../../helpers/trackingHelper'

const argosLogoUrl = 'https://media.4rgos.it/i/Argos/logo_argos2x'
const habitatLogoUrl = 'https://media.4rgos.it/i/Argos/Habitat-logo-black'
const tuLogoUrl = 'https://media.4rgos.it/i/Argos/tu_logo'

const handleHomepageRoute = (evt, routes) => {
  const href = isNotABot() ? `/?clickOrigin=header:${getSiteSection()}argos+logo` : '/'

  if (routes.homepage && typeof routes.homepage === 'function') {
    evt.preventDefault()
    routes.homepage.call(this, href)
  }
}

const Logo = ({ routes, dualBrand = false, triBrand = false }) => {
  const a11yText = 'Logo - go to homepage'
  const href = isNotABot() ? `/?clickOrigin=header:${getSiteSection()}argos+logo` : '/'

  return (
    <div className={styles.logoContainer}>
      <a
        className={styles.logo}
        href={href}
        data-el='header-logo'
        aria-label={a11yText}
        data-test={'header-logo'}
        onClick={(evt) => handleHomepageRoute(evt, routes)}>
        <Image
          className={dualBrand ? styles.argosDualLogo : styles.argosLogo}
          width='120'
          height='103'
          src={argosLogoUrl}
          fallbackFmt='fmt=png'
          alt='Argos logo'
        />

        {triBrand && (
          <Image
            width='120'
            height='103'
            className={styles.tuLogo}
            src={tuLogoUrl}
            fallbackFmt='fmt=png'
            alt='Tu logo'
          />
        )}

        {(dualBrand || triBrand) && (
          <Image
            width='120'
            height='103'
            className={styles.habitatLogo}
            src={habitatLogoUrl}
            fallbackFmt='fmt=png'
            alt='Habitat logo'
          />
        )}

        <span id='argos-logo' className='sr-only' aria-hidden={true}>
          {a11yText}
        </span>
      </a>
    </div>
  )
}

Logo.propTypes = {
  routes: PropTypes.shape({
    help: PropTypes.func,
    stores: PropTypes.func,
    'advice-and-inspiration': PropTypes.func,
    search: PropTypes.func,
    megaButtons: PropTypes.arrayOf(PropTypes.func),
  }),
}

Logo.defaultProps = {
  routes: {},
}

export default Logo
