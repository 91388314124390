/* eslint-disable no-useless-escape */
import { getSearchPath } from '@sainsburys-tech/boltui-utils'

import getSiteSection from './getSiteSection'
import { isNotABot } from './trackingHelper'

export const searchTermIsSkuId = (term) => {
  const skuId = term.trim().match(/^[\d]{3}\/?[\d]{4}$/g)
  const tuSkuId = /^(tuc)[\d]{8,18}$/g.test(term.trim())

  if (skuId || tuSkuId) {
    return true
  }
  return false
}
export const searchTermType = (searchTerm, origin) => {
  if (searchTermIsSkuId(searchTerm)) {
    return 'sku'
  }
  if (origin === 'suggest') {
    return 'auto'
  }
  return 'term'
}
export const searchUrlBuilder = (searchTerm, origin, suggestion) => {
  if (!searchTerm) {
    return '/'
  }
  const type = searchTermType(searchTerm, origin)
  const findabilityUrl = getSearchPath(searchTerm)
  const encodedSearchTerm = findabilityUrl
    .substring(8, findabilityUrl.length - 1)
    .replace(/[\/]/g, '')
    .replace(/-/g, '+')
    .replace('%2F', '')

  if (suggestion) {
    const findabilitySuggestUrl = getSearchPath(suggestion)
    const encodedSuggestion = findabilitySuggestUrl.substring(8, findabilitySuggestUrl.length - 1).replace(/-/g, '+')
    const searchDetails = encodedSearchTerm.concat(':', encodedSuggestion)

    return isNotABot() ? `?clickOrigin=searchbar:${getSiteSection()}${type}:${searchDetails}` : ''
  }

  return isNotABot() ? `?clickOrigin=searchbar:${getSiteSection()}${type}:${encodedSearchTerm}` : ''
}

export const megaMenuLevelOneUrlBuilder = (catLink) => {
  const strippedCatLink = catLink.substring(8, catLink.length - 9).replace(/-/g, '+')
  if (catLink.indexOf('?tag') !== -1) {
    const eventName = strippedCatLink.split('?')
    const url = isNotABot() ? `&clickOrigin=header:${getSiteSection()}menu:${eventName[0]}` : ''
    return encodeURI(url)
  }
  const url = isNotABot() ? `?clickOrigin=header:${getSiteSection()}menu:${strippedCatLink}` : ''
  return encodeURI(url)
}

export const megaMenuUrlBuilder = (text) => {
  const term = text
    .toLowerCase()
    .replace(/ /g, '+')
    .replace(/[\/',]/g, '')
    .replace(/&/g, 'and')
    .replace(/%20/g, '+')
  const url = isNotABot() ? `?clickOrigin=header:${getSiteSection()}menu:${term}` : ''
  return url
}

export const megaButtonsUrlBuilder = (link, text) => {
  const str = isNotABot() ? `&clickOrigin=header:${getSiteSection()}` : ''
  return encodeURI(
    `${link}${str}${text
      .replace(/ /g, '+')
      .replace(/[\/',]/g, '')
      .replace(/&/g, 'and')
      .replace(/%20/g, '+')
      .toLowerCase()}`,
  )
}
