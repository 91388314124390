import React from 'react'

const ReturnIcon = ({ width = '1em', height = '1em', fill = '#333', ...props }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill={fill} width={width} height={height} {...props}>
    <path d='M12 1.5c5.799 0 10.5 4.701 10.5 10.5a10.47 10.47 0 0 1-1.569 5.525l1.366 1.463a.75.75 0 0 1-.442 1.255l-.106.007h-3.5a.75.75 0 0 1-.744-.648L17.5 19.5v-3.75c0-.645.748-.974 1.222-.584l.076.072 1.067 1.143A9 9 0 1 0 12 21a.75.75 0 1 1 0 1.5C6.201 22.5 1.5 17.799 1.5 12S6.201 1.5 12 1.5z' />
  </svg>
)

ReturnIcon.displayName = 'ReturnsIcon'
export default ReturnIcon
