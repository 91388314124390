const ensureStructure = (window) => {
  window.digitalData = window.digitalData || {}
  window.digitalData.page = window.digitalData.page || {}
  window.digitalData.page.attributes = window.digitalData.page.attributes || {}
  window.digitalData.page.attributes.recommendations = window.digitalData.page.attributes.recommendations || []
  window.digitalData.page.clickorigin = window.digitalData.page.clickorigin || {}
  window.digitalData.event = window.digitalData.event || []
  window.digitalData.experiments = window.digitalData.experiments || []
  window.digitalData.experimentList = window.digitalData.experimentList || []
}

const pushEventThenTrack = (eventData) => {
  ensureStructure(window)
  if (typeof window.digitalData !== 'undefined' && !!window.digitalData.track) {
    window.digitalData.event = window.digitalData.event || []

    const events = window.digitalData.event
    events.push(eventData)

    const { eventName, eventRef } = eventData.eventInfo
    eventRef ? window.digitalData.track(eventName, { eventRef }) : window.digitalData.track(eventName)
  }
}

const isNotABot = () => {
  if (typeof navigator !== 'undefined' && navigator.userAgent) {
    return /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator?.userAgent) === false
  }
  return false
}

export { ensureStructure, pushEventThenTrack, isNotABot }
